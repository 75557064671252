





























































































































































































import gql from 'graphql-tag';
import { debounce, isNull, isNumber } from 'lodash';
import * as math from 'mathjs';
import sb from 'satoshi-bitcoin';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import {
  type Category,
  type Connection,
  ConnectionCategory,
  ConnectionStatus,
  type Contact,
  type Metadata,
  Providers,
  ScopeLiterals,
  type Transaction,
  type TransactionData,
  Wallet,
} from '@/api-svc-types';
import { BaseVue } from '@/BaseVue';
import AdvancedDeFiCategorization from '@/components/transactions/categorization/AdvancedDeFiCategorization/AdvancedDeFiCategorization.vue';
import AssetStakingCategorization from '@/components/transactions/categorization/AssetStakingCategorization.vue';
import DeFiTransferCategorization from '@/components/transactions/categorization/DeFiTransferCategorization.vue';
import { NoFiatTransactionsPageQuery } from '@/queries/noFiatTransactionDetails';
import { isTxnClosed } from '@/services/transactionServices';
import { MUT_SNACKBAR } from '@/store';
import { getAccountingProviderIcon } from '@/utils/accountingProviders';
import { stringifyError } from '@/utils/error';
import { asDefined, assertDefined } from '@/utils/guards';

import { TransactionDetailQuery } from '../../queries/transactionsPageQuery';
import AccountTransferCategorization from './categorization/AccountTransferCategorization.vue';
import DetailedCategorization from './categorization/DetailedCategorization.vue';
import InvoiceCategorization from './categorization/InvoiceCategorization.vue';
import StakingCategorization from './categorization/StakingCategorization.vue';
import StandardCategorization from './categorization/StandardCategorization/StandardCategorizationContainer.vue';
import TradeCategorization from './categorization/TradeCategorization.vue';
import TransferCategorization from './categorization/TransferCategorization.vue';

interface TransactionState {
  name: string;
  id: string;
  description: string;
}

type MetadataByType = {
  type: string;
  metadata: Metadata[];
};

@Component({
  components: {
    DeFiTransferCategorization,
    AssetStakingCategorization,
    AccountTransferCategorization,
    DetailedCategorization,
    StakingCategorization,
    TradeCategorization,
    TransferCategorization,
    InvoiceCategorization,
    StandardCategorization,
    AdvancedDeFiCategorization,
    // SellCategorization,
    // SplitCategorization
  },
  apollo: {
    transaction: {
      query: NoFiatTransactionsPageQuery,
      variables() {
        this.isLoading = 1;
        return {
          orgId: this.$store.state.currentOrg.id,
          transactionId: this.txnId,
        };
      },
      loadingKey: 'isLoading',
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      skip() {
        return this.txnId == null;
      },
      error(err: { message: string }) {
        this.error = err?.message;
        this.showErrorSnackbar(this.error ?? 'Unknown Error');
        this.isLoading = 0;
      },
      result() {
        this.isLoading = 0;
      },
      update(data) {
        data.transaction?.txnLines?.forEach((x: any) => {
          if (x?.amount?.startsWith('-')) {
            x.amount = x.amount?.slice(1); // remove minus sign to keep precision and not convert to number.
          }
          if (x?.feeAmount?.startsWith('-')) {
            x.feeAmount = x.feeAmount?.slice(1); // remove minus sign to keep precision and not convert to number.
          }
        });
        return data.transaction;
      },
    },
    metadata: {
      query: gql`
        query getMetadata($orgId: ID!, $connectionId: ID, $includeDisabled: Boolean) {
          metadata(orgId: $orgId, connectionId: $connectionId, includeDisabled: $includeDisabled) {
            id
            enabled
            source
            metaType
            name
            remoteType
            connectionId
          }
        }
      `,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
          connectionId: this.accountingConnectionId,
          includeDisabled: false,
        };
      },
      skip() {
        return !this.accountingConnectionId; // skip if no connection to prevent unnecessary queries
      },
      loadingKey: 'isLoading',
      update(data: { metadata: Metadata[] }) {
        try {
          if (!data.metadata) return [];
          const metadataIds = data.metadata.map((x) => x.id);
          if (
            data.metadata.length === this.previousMetadataIds.length &&
            data.metadata.every((x) => this.previousMetadataIds.includes(x.id))
          ) {
            return this.metadata; // if no change return the exact same object to prevent re-render
          }
          this.previousMetadataIds = metadataIds;
          const metadataByType = data.metadata.reduce((acc, m) => {
            if (!m) return acc;
            const { remoteType } = m;
            if (!acc[remoteType]) {
              acc[remoteType] = { type: remoteType, metadata: [m] };
            } else {
              acc[remoteType].metadata.push(m);
            }
            return acc;
          }, {} as Record<string, MetadataByType>);
          return Object.values(metadataByType);
        } catch (e) {
          console.error('Error transforming metadata', e);
          return [];
        }
      },
    },
  },
})
export default class SaveInline extends BaseVue {
  @Prop()
  public readonly setDirtyTxns!: string[];

  @Prop()
  public readonly categories?: Category[];

  @Prop()
  public readonly contacts?: Contact[];

  @Prop({ required: true })
  public readonly refresh!: () => unknown;

  @Prop()
  public readonly txnId?: string;

  @Prop({ default: false })
  public readonly short!: boolean;

  @Prop({ default: false })
  public readonly eagerRefetch!: boolean;

  @Prop({ required: true })
  public readonly getContact?: (transaction: Transaction) => string;

  @Prop({ required: true })
  public readonly wallets!: Wallet[];

  @Prop({ default: true })
  public readonly useLines!: boolean;

  @Prop({ default: () => [] })
  public readonly connections!: Connection[];

  public transactionData: (TransactionData & { valid?: boolean }) | null = null;
  public serSplitCoin = '';
  public serExchangeRate = '';
  public serFee = '';
  public sffExchangeRate = '';
  public sffFee = '';
  public sffCurrency = '';
  public sffProceedAccount = '';
  public sffFeeContact = '';
  public sffDateTime = '';
  public coins = ['BTC', 'ETH', 'EOS'];
  public currencies = ['USD'];
  public error: string | null = null;
  public transactionStates: TransactionState[] = [
    {
      name: '_new',
      id: 'new',
      description: '_txnMarkedNew',
    },
    {
      name: '_unreconciled',
      id: 'unreconciled',
      description: '_txnMarkedUnreconciled',
    },
    {
      name: '_reconciled',
      id: 'reconciled',
      description: '_txnMarkedReconciled',
    },
  ];

  public previousMetadataIds: string[] = []; // Used to track changes to metadata

  public description = '';
  public transactionStateIndex: number | null = null;

  public get currentTransaction(): TransactionState {
    const txState = this.transactionStates[this.transactionStateIndex ?? 0];
    return {
      name: this.$t(txState.name) as string,
      id: txState.id,
      description: this.$t(txState.description) as string,
    };
  }

  public category?: Category;
  public categoryId = '';
  public contact?: Contact;
  public categorizationMethod: string | null = null;
  public contactId = '';
  public addSplitType = '';
  public splits: unknown[] = [];
  public memo = '';
  public memoSuccessText = '';
  public memoSavingText = 'Unedited';
  public isLoading = 0;
  public isSaving = false;
  public accountingConnectionId: string | null = null;
  public metadata: MetadataByType[] | null = [];

  /**
   * This value was removed in afbe38e1586ccb5a419a0a4995be40e9de158dfc (~3 yrs ago at time of writing).
   * However it is still referenced thorughout this code.
   * This declaration is here to prevent type-errors until it is removed properly.
   * @deprecated Do not use this field, it is undefined and will cause an error.
   * */
  public txnValue!: math.BigNumber;

  // From Apollo
  public transaction?: Transaction;

  public get isTxnLinesValid() {
    if (
      this.checkFeatureFlag('categorization-validation-hotfix', this.features) &&
      !this.includesFiatFee(this.transaction)
    ) {
      return !!this.useLines;
    }
    return true;
  }

  public includesFiatFee(txn: any) {
    const excludedCoins = ['USD'];
    for (const fee of txn?.paidFees?.values() || []) {
      if (excludedCoins.includes(fee?.coin)) {
        return true;
      }
    }

    return false;
  }

  public get btnTitle() {
    if (this.transaction) {
      if (this.readonly) {
        return 'View'; // this.$tc('_view');
      } else {
        return this.transaction.categorizationStatus === 'Uncategorized' ? this.$tc('_categorize') : this.$tc('_edit');
      }
    }

    return undefined;
  }

  public get canSave() {
    if (this.transactionData && this.transactionData.valid === true) {
      return true;
    }
    return false;
  }

  public get supportedMethods() {
    const standard = {
      id: 'standard',
      name: 'Standard',
    };

    const ret = [standard];

    const trade = {
      id: 'trade',
      name: 'Trade',
    };

    const transfer = {
      id: 'transfer',
      name: 'Internal Transfer',
    };

    const accountTransfer = {
      id: 'accountTransfer',
      name: 'Account Transfer',
    };

    const invoice = {
      id: 'invoice',
      name: 'Invoice / Bill Payment',
    };

    const deFiTransfer = {
      id: 'deFiTransfer',
      name: 'DeFi Transfer',
    };

    const assetStaking = {
      id: 'assetStaking',
      name: 'DeFi',
    };

    if (this.checkFeatureFlag('asset-staking', this.features)) {
      ret.push(assetStaking);
    }

    if (this.transaction) {
      if (this.transaction.type === 'Receive') {
        if (this.transaction.amounts?.length === 1) {
          ret.push(accountTransfer);
        }
        if (this.checkFeatureFlag('asset-staking', this.features)) {
          ret.push(invoice, assetStaking);
        } else {
          ret.push(invoice);
        }
      } else if (this.transaction.type === 'Send') {
        if (this.transaction.amounts?.length === 1) {
          ret.push(accountTransfer);
        }
        if (this.checkFeatureFlag('asset-staking', this.features)) {
          ret.push(invoice, assetStaking);
        } else {
          ret.push(invoice);
        }
      } else if (this.transaction.type === 'Transfer') {
        ret.push(...[transfer, deFiTransfer]);
      } else if (this.transaction.type === 'Trade') {
        if (this.checkFeatureFlag('asset-staking', this.features)) {
          ret.push(trade, assetStaking);
        } else {
          ret.push(trade);
        }
      } else {
        if (this.checkFeatureFlag('asset-staking', this.features)) {
          ret.push(trade, assetStaking);
        } else {
          ret.push(trade);
        }
      }
      // we just care that there are 2 wallets interacting
      if (
        !ret.some((x) => x.id === 'transfer') &&
        (this.transaction.fullAmountSetWithoutFees?.length ?? 0) > 1 &&
        this.transaction.fullAmountSetWithoutFees?.every(
          (x) => this.transaction?.fullAmountSetWithoutFees?.[0]?.coin ?? x?.coin === ''
        )
      ) {
        ret.push(transfer);
      }
      if (this.checkFeatureFlag('allow-trade-override', this.features) && !ret.some((x) => x.id === 'trade')) {
        ret.push(trade);
      }
    }
    if (this.checkFeatureFlag('advanced-defi', this.features)) {
      const advanceDeFi = {
        id: 'advancedDeFi',
        name: 'Advanced DeFi',
      };
      ret.push(advanceDeFi);
    }
    return ret;
  }

  public get supportedMethodsList() {
    const ret = ['standard'];
    if (this.transaction) {
      if (this.transaction.type === 'Transfer') {
        ret.push('transfer');
      } else if (this.transaction.type === 'Trade') {
        ret.push('trade');
      }
    }
    return ret;
  }

  public get txnValues() {
    return this.transaction?.amounts?.map((m) => math.bignumber(m?.value));
  }

  public get serProceeds() {
    if (this.serExchangeRate) {
      const i = this.txnValue.mul(Number(this.serExchangeRate) * -1);
      return math.round(i, 8);
    }

    return undefined;
  }

  public get typeQuery() {
    if (this.txType === 'spend') {
      return 'Expense';
    } else if (this.txType === 'receive') {
      return 'Revenue';
    } else if (this.txType) {
      return 'Expense';
    } else {
      return 'unknown';
    }
  }

  public get splitTypes() {
    if (this.txType === 'spend') {
      return [
        {
          title: 'Exchange for Crypto',
          id: 'exchange',
        },
        {
          title: 'Sell for Fiat',
          id: 'sellForFiat',
        },
        {
          title: 'Transfer',
          id: 'transfer',
        },
      ];
    } else if (this.txType === 'receive') {
      return [
        {
          title: 'Buy with Fiat',
          id: 'sellForFiat',
        },
        {
          title: 'Transfer',
          id: 'transfer',
        },
      ];
    }

    return undefined;
  }

  public get txType() {
    if (this.transaction?.type === 'Send') {
      return 'spend';
    } else if (this.transaction?.type === 'Receive') {
      return 'receive';
    } else if (this.transaction?.type === 'Trade') {
      return 'trade';
    } else {
      return 'unknown';
    }
  }

  public get readonly() {
    if (!this.checkScope(ScopeLiterals.TransactionCategorizeUpdate)) {
      return true;
    }
    if (this.transaction) {
      if (isTxnClosed(this.transaction, this.$store.state.currentOrg)) {
        return true;
      }
      return this.transaction.readonly;
    } else {
      return false;
    }
  }

  public get filteredContacts(): Contact[] {
    if (!this.contacts) return [];
    if (this.accountingConnectionId) {
      return this.contacts.filter((c) => c.accountingConnectionId === this.accountingConnectionId);
    }

    return this.contacts;
  }

  public get filteredCategories(): Category[] {
    if (!this.categories) {
      return [];
    }
    const syncCheck = (category?: Category, connection?: Connection) => {
      if (!connection || connection.provider !== Providers.NetSuite) {
        return true;
      }
      if (!category?.lastUpdatedSEC || !connection?.lastSyncSEC) return true;
      return Math.abs(category.lastUpdatedSEC - connection.lastSyncSEC) / (1000 * 60) <= 20; // 20 minutes
    };
    if (this.accountingConnectionId) {
      return this.categories
        .filter(
          (c) =>
            c.accountingConnectionId === this.accountingConnectionId &&
            syncCheck(
              c,
              this.connections.find((x) => x.id === this.accountingConnectionId)
            )
        )
        .sort((a, b) => {
          //  we will try to sort by the account code
          if (isNumber(a.code) && isNumber(b.code)) {
            return Number(a.code) - Number(b.code);
          }

          //  default is a string comparison on name
          return a.name.localeCompare(b.name);
        });
    }

    return this.categories;
  }

  showErrorSnackbar(message: string): void {
    this.$store.commit(MUT_SNACKBAR, {
      color: 'error',
      message: message,
    });
  }

  public async mounted() {
    if (this.setDirtyTxns?.includes(this.transaction?.id ?? '')) {
      this.isLoading = 1;
      await this.refetch();

      // Remove transaction from dirty after refetch is done for transaction
      const index = this.setDirtyTxns.indexOf(this.transaction?.id ?? '');
      if (index > -1) {
        // only splice array when item is found
        this.setDirtyTxns.splice(index, 1); // 2nd parameter means remove one item only
      }
      this.isLoading = 0;
    }
  }

  public get connectionProviderCounts(): Record<Providers, number> {
    return this.connections.reduce(
      (a, x) => ({
        ...a,
        [x.provider]: (a[x.provider] ?? 0) + 1,
      }),
      {} as Record<Providers, number>
    );
  }

  public get validAccountingConnections(): Connection[] {
    const accountingConnections = this.connections.filter((c) => {
      //  we removed the filter for isDelete because it is possible
      //  user used a certain accounting connection then they migrate to a new one

      return c.category === ConnectionCategory.AccountingConnection;
    });

    const manualContactExist = this.contacts?.some((c) => c.accountingConnectionId === 'Manual') ?? false;
    const manualCategoryExist = this.categories?.some((c) => c.accountingConnectionId === 'Manual') ?? false;

    if (manualContactExist || manualCategoryExist) {
      const manualAccountingConnection: Connection = {
        id: 'Manual',
        provider: Providers.Manual,
        status: ConnectionStatus.Ok,
      };
      return accountingConnections.concat(manualAccountingConnection);
    }

    return accountingConnections;
  }

  public addExchangeSplit() {
    const item = {
      title: 'Exchanged for ' + this.serProceeds + ' ' + this.serSplitCoin,
      exchangeRate: Number(this.serExchangeRate),
      coin: this.serSplitCoin,
      fee: Number(this.serFee),
    };

    this.splits.push(item);
    this.addSplitType = '';
    this.serSplitCoin = '';
    this.serExchangeRate = '';
    this.serFee = '';
  }

  public addSellForFiatSplit() {
    const item = {
      title: 'blah',
    };
    this.splits.push(item);
    this.addSplitType = '';
  }

  public populateForm() {
    this.transactionStateIndex = 0;

    if (this.transaction) {
      this.accountingConnectionId = this.transaction.accountingConnectionId ?? null;

      if (isNull(this.accountingConnectionId)) {
        this.accountingConnectionId = this.getDefaultAccountingConnection();
      }

      this.memo = this.transaction.memo ?? '';
      if (this.transaction.accountingDetails) {
        if (this.transaction.accountingDetails.length === 1) {
          const ad = this.transaction.accountingDetails[0];
          if (!ad) {
            return;
          } else if (ad.simple) {
            this.categorizationMethod = 'standard';
          } else if (ad.staking) {
            this.categorizationMethod = 'staking';
          } else if (ad.detailed) {
            this.categorizationMethod = 'detailed';
          } else if (ad.transfer) {
            this.categorizationMethod = 'transfer';
          } else if (ad.accountTransfer) {
            this.categorizationMethod = 'accountTransfer';
          } else if (ad.trade) {
            this.categorizationMethod = 'trade';
          } else if (ad.multivalue) {
            this.categorizationMethod = 'standard';
          } else if (ad.invoice) {
            this.categorizationMethod = 'invoice';
          } else if (ad.assetStaking) {
            this.categorizationMethod = 'assetStaking';
          } else if (ad.deFiTransfer) {
            this.categorizationMethod = 'deFiTransfer';
          } else if (ad.advanceDeFi) {
            this.categorizationMethod = 'advancedDeFi';
          }
        }
      }
    } else {
      this.accountingConnectionId = this.getDefaultAccountingConnection();
    }

    if (this.categorizationMethod === undefined || this.categorizationMethod === null) {
      if (this.transaction && this.transaction.matchedInvoices && this.transaction.matchedInvoices.length > 0) {
        this.categorizationMethod = 'invoice';
      } else if (this.transaction?.type === 'Trade') {
        this.categorizationMethod = 'trade';
      } else if (this.supportedMethods.length > 0) {
        this.categorizationMethod = this.supportedMethods[0].id;
      }
    }
  }

  public async save() {
    this.isSaving = true;
    assertDefined(this.transactionData);
    const vars = {
      orgId: this.$store.state.currentOrg.id,
      txnId: this.txnId,
      transactionData: this.transactionData,
    };

    window.pendo?.track('Transaction - Categorized Transaction', {});

    delete vars.transactionData.valid;

    /** @deprecated Do not use, legacy code */
    function deleteValidAndExchangeRates(obj: any) {
      delete obj.valid;
      delete obj.exchangeRates;
    }

    if (vars.transactionData.simple && vars.transactionData.simple.costBasis) {
      deleteValidAndExchangeRates(vars.transactionData.simple.costBasis);
    }

    if (vars.transactionData.transfer && vars.transactionData.transfer.feeCostBasis) {
      deleteValidAndExchangeRates(vars.transactionData.transfer.feeCostBasis);
    }

    if (vars.transactionData.staking && vars.transactionData.staking.costBasis) {
      deleteValidAndExchangeRates(vars.transactionData.staking.costBasis);
    }

    vars.transactionData.accountingConnectionId = asDefined(this.accountingConnectionId);
    if (this.transaction && this.transaction.editEtag) {
      vars.transactionData.editEtag = this.transaction.editEtag;
    }

    try {
      const resp = await this.$apollo.mutate({
        // Query
        mutation: gql`
          mutation ($orgId: ID!, $txnId: ID!, $transactionData: TransactionData!) {
            categorizeTransaction(orgId: $orgId, txnId: $txnId, transactionData: $transactionData) {
              id
            }
          }
        `,
        // Parameters
        variables: vars,
      });

      this.description = '';
      this.category = undefined;
      this.contact = undefined;
      this.sffFee = '';
      this.sffExchangeRate = '';
      this.sffProceedAccount = '';

      this.refresh();
      await this.refetch();
      this.$emit('saved', { id: resp?.data?.categorizeTransaction?.id });
      this.isLoading = 0;
    } catch (e) {
      let rawMsg = stringifyError(e, { hideErrorName: true });
      rawMsg = rawMsg.replace(/^GraphQL error: /, '');
      const message = 'Problem saving item: ' + rawMsg;
      this.$store.commit(MUT_SNACKBAR, {
        color: 'error',
        message,
      });
      this.isSaving = false;
    }
  }

  public toBitcoin(sat: string | number) {
    return sb.toBitcoin(sat);
  }

  public async refetch() {
    await this.$apollo.queries.transaction.refetch();
    this.populateForm();
  }

  public debouncedSaveMemo = debounce(this.saveMemo.bind(this), 1000);

  public saveMemo() {
    if (!this.txnId || !this.$store.state.currentOrg.id) {
      return;
    }

    this.memoSuccessText = '';
    this.memoSavingText = 'Saving';
    const vars = {
      orgId: this.$store.state.currentOrg.id,
      id: this.txnId,
      memo: this.memo,
    };

    this.$apollo
      .mutate({
        mutation: gql`
          mutation ($orgId: ID!, $id: ID!, $memo: String!) {
            updateTransactionMemo(orgId: $orgId, id: $id, memo: $memo) {
              id
              memo
            }
          }
        `,
        variables: vars,
      })
      .then(() => {
        this.memoSuccessText = 'Saved';
      })
      .catch(() => {
        this.memoSuccessText = 'Errored in saving.';
      })
      .finally(() => {
        this.memoSavingText = '';
      });
  }

  public getProviderIcon(provider: Providers) {
    return getAccountingProviderIcon(provider);
  }

  public getConnectionName(conn: Connection) {
    let name: string | null | undefined = conn.name;
    if (conn.provider === 'Manual') {
      name = 'Bitwave';
    }

    if (!name) {
      name = conn.provider;
      if (this.connectionProviderCounts[conn.provider] > 1) {
        return `${name} (${conn.id})`;
      } else {
        return name;
      }
    } else {
      return name;
    }
  }

  public getDefaultAccountingConnection() {
    const defaultAccountingConnection = this.validAccountingConnections.find((connection) => connection.isDefault);
    const defaultAccountingConnectionId =
      defaultAccountingConnection?.id ?? this.validAccountingConnections[0]?.id ?? null;
    return defaultAccountingConnectionId;
  }

  @Watch('memo')
  public onMemoChange(newMemo: string, oldMemo: string) {
    if (newMemo !== this.transaction?.memo) {
      this.debouncedSaveMemo();
    }
  }

  @Watch('transaction')
  public onTransactionChange(_: Transaction, old?: Transaction) {
    if (!old) {
      this.populateForm();
    }
  }

  @Watch('categories')
  public onCategoriesChange() {
    if (this.categories) {
      const found = this.categories.find((m) => m.id === this.categoryId);
      this.category = found;
    }
  }

  @Watch('contacts')
  public onContactsChange() {
    if (this.contacts) {
      const found = this.contacts.find((m) => m.id === this.contactId);
      this.contact = found;
    }
  }

  async created() {
    if (this.eagerRefetch && !this.isLoading) {
      this.isLoading = 1;
      await this.refetch();
      this.isLoading = 0;
    }
  }
}
