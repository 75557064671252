import { Providers } from '@/api-svc-types';

const providerIcons: Record<Providers, string> = {
  Xero: '/xero.png',
  QuickBooks: '/qb.png',
  Binance: '/binance.png',
  CoinbasePro: '/coinbasepro.png',
  Bitfinex: '/bitfinex.png',
  BitGo: '/bitgo.png',
  Kraken: '/kraken.png',
  ItBit: '/itbit.png',
  Bitstamp: '/bitstamp.png',
  Gemini: '/gemini.png',
  Paxos: '/paxos.png',
  NyDig: '/nydig.jpeg',
  Bittrex: '/bittrex.png',
  Ftx: '/ftx.png',
  CoinbaseCustody: '/coinbase-custody.png',
  NetSuite: '/netsuite.png',
  SFOX: '/sfox.png',
  SageIntacct: '/sageintacct.png',
  CoinbaseCommerce: '/coinbase-commerce.ico',
  PrimeTrust: '/primetrust.png',
  Manual: '/mark.png',
  Anchorage: '/anchorage.png',
  CoinbasePrime: '/coinbaseprime.png',
  Fireblocks: '/fireblocks.png',
  CoinbaseRetail: '/coinbase-retail.png',
  Request: 'request.png',
  CryptoDotCom: '/crypto-com.png',
  Circle: '/circle.png',
  Foundry: '/foundry.png',
  OracleFusion: '/oracle.png',
};

/**
 * Returns the icon for the provider
 * @param provider The provider name
 * @returns The icon for the provider
 */
export function getAccountingProviderIcon(provider: Providers): string {
  return providerIcons[provider] ?? '/question-circle-solid.svg';
}

export function isAccountingProvider(provider: Providers): boolean {
  const accoutingProviders = [
    Providers.Xero,
    Providers.QuickBooks,
    Providers.NetSuite,
    Providers.SageIntacct,
    Providers.Request,
    Providers.OracleFusion,
  ];
  return accoutingProviders.includes(provider);
}
